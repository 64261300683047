'use client';

import React, { useContext } from 'react';

import { useRouter } from 'next-nprogress-bar';

import { PreviousPathnameContext } from 'components/globals/PreviousPathnameProvider';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import Text from 'components/ui/Text';

import styles from './NotFound.module.scss';

const ErrorPage = () => {
    const router = useRouter();
    const previousPathname = useContext(PreviousPathnameContext);

    return (
        <div className={styles.container}>
            <Text
                className={styles.headline}
                as="h1"
                baseTheme="displaySmall"
                themes={{ medium: 'displayLarge' }}
            >
                Well, this isn’t grate
            </Text>
            <Text
                className={styles.copy}
                as="p"
                baseTheme="bodyMedium"
                themes={{ medium: 'bodyLarge' }}
            >
                We can’t seem to find the page you’re looking for. Sorry about
                that!
            </Text>
            {previousPathname ? (
                <ButtonCTA
                    text="Back"
                    style="filled-blue-ocean"
                    onClick={() => router.back()}
                />
            ) : (
                <ButtonCTA
                    text="Home"
                    style="filled-blue-ocean"
                    link={{ slug: '/' }}
                />
            )}
            <figure className={styles.imageWrapper}>
                <Img
                    src="https://images.ctfassets.net/j8tkpy1gjhi5/3jkzmNp6SF4bsl2liTNiRA/7ae0f7a8be5008608cdfe1764594cf81/DIGITAL-shreds-side---KO_1.png"
                    fallbackImageWidth={1274}
                    alt="Cheese Shreds"
                />
            </figure>
        </div>
    );
};

export default ErrorPage;
